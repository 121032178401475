import { requestsResponseHelper } from 'src/features/requests/requests-response.helper';

export const requestsConditionalUiHelper: {
  shouldDisplayExpectedEditingTime: (request: {
    flow: string | any;
    status: string | any;
    revisions_count?: number;
  }) => boolean;
  shouldDisplayExpectedReeditingTime: (request: {
    flow: string | any;
    status: string | any;
    revisions_count?: number;
    requested_reedit_at?: string | null;
    approved_at?: string | null;
  }) => boolean;
} = {
  // Since were now showing values all the time I guess this one is deprecated
  shouldDisplayExpectedEditingTime: (request) =>
    !requestsResponseHelper.isFolder(request),
  shouldDisplayExpectedReeditingTime: (request) =>
    !requestsResponseHelper.isFolder(request),
};
